import React from "react";

export const Helpcenter = (props) => {
    return (
        <div id='policy' >
            <div className="">
                <div className="policyinto">
                <img src="img/banners/helpc.png" className="" alt="return-policies" />{" "}

                </div>
                <div className="container">
                    <div className='section-title text-center' style={{marginTop:'50px'}}>
                        <h2>HELP CENTER</h2>
                    </div>
                    <div className="policytext">
                        
                        <p>Welcome to the Aqua Pure RO Services Help Center. We're here to assist you with any queries, issues, or support you might need regarding our services and products.</p>

                        <h3><b><u>Contact Information</u></b></h3>
                        <h4><b>Customer Support Email:</b>&nbsp; <u>aquapureroservices@gmail.com</u></h4>
                        <h4><b>Customer Support Phone:</b>&nbsp; <u>9031062011</u></h4>

                        <h3><b><u>Return and Refund Policy</u></b></h3>
                        <p>For information regarding returns and refunds, please refer to our <strong><a href="/return-policy">Return Policy</a>&nbsp;</strong>. If you have any specific queries, contact us at aquapureroservices.com.</p>

                        <h3><b><u>Feedback and Complaints</u></b></h3>
                        <p>We value your feedback and strive to improve our services continuously. If you have any feedback or complaints, please email us at aquapureroservices@gmail.com.</p>
                        
                        <h3><b><u>Warranty and Guarantee Information</u></b></h3>
                        <p>For details about warranties and guarantees on our services and parts, please visit our Warranty Information page or contact customer support.</p>


                        
                    </div>
                
                </div>

            </div>
        </div>
    )
}