import React from "react";

export const Returnypolicy = (props) => {
    return (
        <div id='policy' >
            <div className="">
                <div className="policyinto">
                    <img src="img/banners/Return.png" className="" alt="return-policies" />{" "}

                </div>
                <div className="container">
                    <div className='section-title text-center' style={{ marginTop: '50px' }}>
                        <h2>RETURN POLICY</h2>
                    </div>
                    <div className="policytext">

                        <p>Thanks for shopping at Aquapure RO Services . We really appreciate your support and preference for
                            the service we create. We also want to make sure you have a rewarding experience while you're
                            exploring, evaluating and purchasing our Service. W e exert our full strength to ensure that the service
                            we provide built to your needs. We understand, however, that sometimes a product may not be what
                            you expected it to be. In that unlikely even t, we invite you to review the following terms related to
                            warranting and returning a product</p>

                        <h3><b><u>Criteria for Returns:</u></b></h3>
                        <ul>
                            <li><b>• Defective Services or Parts:</b>If the service provided or parts replaced are found to be defective or malfunctioning.</li>
                            <li><b>• Incorrect Parts:</b>If parts installed are not as per the initial agreement or are incorrect for the specific RO model.</li>
                            <li><b>• Unsatisfactory Service:</b>If the customer is not satisfied with the service quality or the issue persists post-service.</li>
                        </ul>

                        <h3><b><u>Processing Time:</u></b></h3>
                        <ul>
                            <li>• Upon receiving a return request, the company will initiate an investigation within 5 business days.</li>
                            <li>• The entire return process, including inspection and resolution, will typically be completed within 15 business days.</li>
                            <li>• Refunds, if applicable, will be processed within 5 business days after the resolution.</li>
                        </ul>
                        <h3><b><u>SUPPORT:</u></b></h3>
                        <p>For return requests or any related inquiries, please contact us at:<b><u>Aquapureroservices.com</u></b></p>
                        <p>If you have any further questions or need assistance with the return process, please do not hesitate to reach out to our customer service team</p>
                        <h3><b><u>Warranty:</u></b></h3>
                        <li> Warranty with one-year Free Service.</li>
                        <li> The Aquapure RO Service is warranted for the period of one year from the date of purchase against any manufacturing defects arise during the period of warranty.</li>
                        <li> If this Aquapure RO System develops any defecting warranty period, it will be repaired at free of cost.</li>
                        <li> If this Aquapure RO System develops any defect during the prod of warranty please bring the unit with warranty card and sales receipt to the service Centre.</li>
                        <li> This unit is meant for the domestic use for the family of up to 7 members.</li>
                        <li> The warranty shall not apply to replacement of Consumable parts like filters, membrane etc.</li>
                        <li> Plastic and Glass parts &UV which are breakable are not covered under this warranty.</li>
                        <li> Liability under the terms of warranty is limited to the value of the repairs /replacement of the parts found to be defective within the warranty period. All parts which are replaced shall have been become the property of the company.</li>
                        <li> The unit shall be installed, used and maintained in accordance with the instruction manual & used under specified water condition limits.</li>
                        <li> If the unit is tampered with or opened by any unauthorized person, the warranty is void.</li>
                    </div>

                </div>

            </div>
        </div>
    )
}