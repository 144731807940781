import React from 'react'

export const Pricetag = (props) => {
  return (
    <div id='pricetag'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2 className='text-center'>Pricing</h2>
        </div>
        <div className='box'>
          <div className="row">
            
            <div className="col-sm-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Repair Service</h5>
                  <ul>
                    <li><img src="./img/pricetag/REPAIR_SERVICE.png" className="lazy" alt="ro repair" />
                      <div className="title_service">Repair Service</div>
                      <div className="service_details_">
                        <ul>
                          <li><i className="fa fa-check-circle" aria-hidden="true"></i> Price include visit &amp; diagnosis charges</li>
                          <li><i className="fa fa-check-circle" aria-hidden="true"></i> Spare part rate applicable as per rate card</li>
                          <li><i className="fa fa-check-circle" aria-hidden="true"></i>  Chip, control box repair charge applicable extra</li>
                          <li><i className="fa fa-check-circle" aria-hidden="true"></i> Water Quality Test (TDS)</li>

                        </ul>
                      </div>
                      <div className="book_now_starting">
                        <div className="rate_price pr">
                          <span>Rs. 299/-</span>
                        </div>
                        <div className="tsapply">
                          <div className="text-danger">*T&amp;C Apply <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span className="">

                              <p><strong>Note:</strong> Price may vary according to Distance, Brand, Model &amp; Condition of Products </p>

                            </span>
                          </div>
                        </div>
                        <a href='/#contact' className='btn btn-pricetag page-scroll' >
                          Book Now
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Routine Service</h5>
                  <ul>
                    <li><img src="./img/pricetag/RO_INSTALLATION.png" className="lazy" alt="installation" />
                      <div className="title_service">Installation</div>
                      <div className="service_details_">
                        <ul>
                          <li><i className="fa fa-check-circle" aria-hidden="true"></i> Include Fixing of water purifier stand on wall </li>
                          <li><i className="fa fa-check-circle" aria-hidden="true"></i> Connect inlet water assembly to water purifier </li>
                          <li><i className="fa fa-check-circle" aria-hidden="true"></i> Connect electricity supply to water purifier  </li>
                          <li><i className="fa fa-check-circle" aria-hidden="true"></i> Free Water Quality Test (TDS) every time</li>

                        </ul>
                      </div>
                      <div className="book_now_starting">
                        <div className="rate_price pr">
                          <span>Rs. 499/-</span>
                        </div>
                        <div className="tsapply">
                          <div className="text-danger">*T&amp;C Apply <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span className="">

                              <p><strong>Note:</strong> Price may vary according to Distance, Brand, Model &amp; Condition of Products </p>

                            </span>
                          </div>
                        </div>
                        <a href='/#contact' className='btn btn-pricetag page-scroll' >
                          Book Now
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}