import React from "react";

export const TermsCondition = (props) => {
    return (
        <div id='policy' >
            <div className="">
                <div className="policyinto">
                    <img src="img/banners/terms.png" className="" alt="return-policies" />{" "}

                </div>
                <div className="container">
                    <div className='section-title text-center' style={{ marginTop: '50px' }}>
                        <h2>TERMS & CONDITION</h2>
                    </div>
                    <div className="policytext">
                        <p>Under this Contract "Aquapure RO Services" undertakes to maintain your water Purifier installed at the address mentioned as per conditions overleaf. this contract provides for free replacement of spare parts and periodical service to the Purifier subject to the terms and conditions mentioned here after the charges for such maintenance are as mentioned:</p>

                        <h3><b><u>SERVICE COVERED BY THE CONTRACT CHARGE ARE AS FOLLOW:</u></b></h3>
                        <ol>
                            <li>1. IN CASE OF Ultra Violet (UV) Purifier Two periodical service visits per year & in case of Reverse Osmosis Two periodical service visits per year during the period of contract mentioned overleaf serviced and adjusted.</li>
                            <li>2.Any additional visits during the contract period, as and when required, in the event of any breakdown malfunctioning of equipment, on intimation if this regards by the customer.</li>
                            <li>3.For UV Purifier replacement of pre filter candle will be made once (maximum) every year depending upon the condition of the existing profiler candle during the service contract period.</li>
                            <p>In case of AG Compact Cartridge two candles per year will be replaced(maximum) depending upon the condition</p>
                            <li>4.For UV purifier: replacement of activated carbon granules will be made twice /activated carbon block once (Maximum) every year during the service contract period.</li>
                            <li>5.For Reverse Osmosis: Replacement of parts asper mentioned maximum limit on first page depending upon the condition during the service contract period.</li>
                        </ol>

                        <h3><b><u>TERMS AND CONDITIONS OF THE CONTRACT:</u></b></h3>

                        <p><b>&nbsp;A) The contract shall not cover visit/ replacement of parts under the following circumstance:</b></p>

                        <ul>
                            <li>a) Damage caused of the equipment due to floods fire accident not breakage dust misuse improper or negligent use tampering leakage from pipes etc.</li>
                            <li>b) Damage caused to the equipment due to failure to observe the operation in user’s Manual.</li>
                            <li>c) Defects due to usage of non-recommended spare.</li>
                            <li>d) Visits for customer re-training of for re-installation at a different location.</li>
                            <li>e) Defects/ failure resulting from servicing of RO Water purifiers.</li>
                            <li>f) Where this Equipment is under use for more than8 hours a day continuously unless otherwise agreed in writing by "Aquapure RO water purifiers".</li>
                            <li>g) There is a waiting period for 3 months on Pump and SMPS repair on renewal of discontinued AMC or fresh new AMC.</li>
                            <li>h) Waiting per ion is not valid for without break renewed AMC. It means that AMC must be renewed before expiry or within waiting period of 7 days after expiry.</li>
                            <li>i) Any false or unnecessary visit made by us on demand/ request called by the customer then this visit has to be charged.</li>

                            <li>With regard to repairing the existing worn-put defective part(s) or replacing the same and ascertaining the presence of above circumstances the decision of ‘Aquapure RO Services’ Shall be final.</li>
                            <li>In any such event, "Aquapure RO Services" will submit a prior estimate for approval or bill for the carried out at the prevailing rates.</li>
                            <li>All order Price is the combination of Part A and Part B. Part A is the details of consumable parts which has to be used in this contract period and this is not refundable. Parts B is the details of the AMC and it can be refundable on the pro-data basis either by Customer or by the Company at any movement.</li>
                        </ul>
                        <ul>
                            <li>A. The Life of Consumable parts depends upon the quantity of usage & the Quality of the input water so any extra part/ parts (Above the maximum Limits) can be used at the request of customer which will be changed extra at the prevailing rated.</li>
                            <li>B. Services under this contract exclude the Following:</li>
                            <li>&nbsp;a) Painting or the body top and bottom panels and anodizing the treatment chambers and electronic housing.</li>
                            <li>&nbsp;b) Chrome plating of nuts and other styling decorate material’s</li>
                            <li>C. The equipment must be in good working condition and the same to be certified by the authorized representative of "Aquapure RO Services" before this contract becomes effective the customer shall pay the necessary charges in the first instance for rending the equipment so workable for which ‘Aquapure RO Services’ will submit an estimate the next day of date of termination of the warranty period or previous contract period.</li>
                            <li>D. The equipment should be made available for servicing when our Technician call on this periodical servicing visit Failure to do so will be treated as service rendered and no refund shall accrue on this account.</li>
                            <li>E. Every visit by our Authorized Service Technician will be made within reasonable time from receipt of a complaint for breakdown of the equipment and will be made only during working Hours of Service Department no visit will made on Saturday, Sundays/ Holidays.</li>
                            <li>F. The liability of "Aquapure RO Services" shall be limited be limited to providing service and replacing spares as covered by this contract and "Aquapure RO Services" not be liable for damages, death, injuries or any purifiers not be liable for damages death injuries any consequential damages of what so ever nature to any person or property, by use or operation of the product.</li>
                            <li>G. In order to enable "Aquapure RO Services" discharge its obligation under this contract the customer shall notify in writing any change in address within 10 days of such change. In default where of "Aquapure RO Services" shall not be obligation under this contract till it receives such an-intimation from the customer. In such an event the removal and re-installation of the Purifier/ Attachment will be done at an additional cost as per the rates prevailing on that date.</li>
                            <li>H. This contract is not transferable in the event of resale/ gift to any other person.</li>
                            <li>I. The service contract is offered only within the municipal limits of your city However an additional amount of RS. 99/- will be charged extra for each visit made beyond these limits but within the metropolitan area and will have to be paid in cash to the service Technician attending for which a cash memo/bill will be given.</li>
                            <li>J. Contract charges payable by Cash or Cheque/DD Favoring "Aquapure RO Services" In advance and payment shall accompany the signed copy of the contract such payment shall be realized before the commencement of the contract period.</li>
                            <li>K. Renewal of the Service Contract after its expiry will be at the sole discretion</li>
                            <li>L. In the event of any complaint Please quote your name address and the contract number to the authorized service Centre.</li>
                            <li>M. This contract sets out all the terms and conditions of which "Aquapure RO Services" agrees to services the equipment and cancels and supersedes all prior agreement undertaking.</li>
                            <li>N. Up gradation: in case product needs upgradation due to reason not under control of RO Water Purifier reason not under control of "Aquapure RO Services" Customer has to accept up gradation at prevailing rate.</li>
                            <li>O. Subject to Delhi Jurisdiction.</li>

                        </ul>

                    </div>

                </div>

            </div>
        </div>
    )
}