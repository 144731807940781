import React from 'react';

export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
            <div className='row intro-text'>
              <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12 '>
                <div className="navbar-status">
                  <span className="" style={{ marginTop: '30px' }}>
                    {/*<span className="blinking-online"><i className='fa fa-circle' style={{marginLeft:'15vh'}}></i></span><br/> */}
                    <div className="blinking-text">
                      <h2>AVA<span className="blinking-dot"><b>i</b></span>LABLE</h2>
                    </div>
                  </span>
                </div>
              </div>

              <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12 '>
                <div className="call-action-area">
                  <span className="number"><a href="https://api.whatsapp.com/send?phone=919031062011" target="_blank" style={{ fontWeight: 'bold' }}><i className="fa fa-whatsapp" style={{ backgroundColor: '#25D366' }}></i>  +91-9031062011 </a></span>
                  {/*<span className="request"><a data-toggle="modal" data-target="#newModalForWizard">Request a Call Back</a></span> */}
                </div>
              </div>

              <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12 '>
                <div className="call-action-area">
                  <span className="number"><a href="tel:9031062011 " style={{ fontWeight: 'bold' }}><i className="fa fa-phone"></i>  +91-9031062011 </a></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
