import React from 'react';


const Chooseus = () => {
  return (
    <>
      <div id='chooseus' className='text-center'>
        <div className='container section-title'>
          <h2>WHY CHOOSE US</h2>
          <h3>We specialize in Water Purification Equipment. We aim to provide only the best customer service and strongly believe our customer support differentiates us from other suppliers. </h3>
          <div className="chooseimg col-lg-3">
            <p ><img src="img/chooseus/customer-support.jpg" /></p>
            <h3>Service Within 24 Hour</h3>
            <p>24X7 services are available to customers in India</p>
          </div>
          <div className="chooseimg col-lg-3">
            <p><img src="img/chooseus/moneyback.jpg" /></p>
            <h3>Money Back Guarantee</h3>
            <p>Worry-free shopping, we gives you satisfaction guarantee</p>
          </div>
          <div className="chooseimg col-lg-3">
            <p ><img src="img/chooseus/freeinstall.png" /></p>
            <h3 >Free Installation</h3>
            <p>We offer free Ro Water Purifier Installation Services</p>
          </div>
          <div className="chooseimg col-lg-3">
            <p><img src="img/chooseus/homeservice.jpeg" /></p>
            <h3>Free Shipping</h3>
            <p>Online shopping from a great selection at FREE Shipping</p>
          </div>
        </div>
      </div>

    </>
  );
}

export default Chooseus;