import React, { useEffect, useState } from 'react'

export const Navigation = (props) => {

  {/*const [navbarColor, setNavbarColor] = useState('transparent');

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;

      // Change the color based on the scroll position
      if (position > 100) {
        setNavbarColor('black'); // Set the desired color when scrolled  tochange back color css nabar-default background co;or white
      } else {
        setNavbarColor('transparent'); // Set the default color when not scrolled
      }
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);*/}
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top' >{/*style={{ backgroundColor: navbarColor }}*/}
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
            onClick={handleMenuClick}
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a className='navbar-brand page-scroll' href='/'>
            AQUA PURE <br /> &nbsp; RO SERVICES
          </a>{' '}
        </div>

        <div
        className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
          <li>
          <a href='#contact' className='page-scroll' onClick={handleLinkClick}>
            Contact
          </a>
        </li>
            {/*<li>
              <a href='#features' className='page-scroll'>
                What We Do
              </a>
            </li> */}
            <li>
              <a href='#about' className='page-scroll' onClick={handleLinkClick}>
                About
              </a>
            </li>
            <li>
              <a href='#pricetag' className='page-scroll' onClick={handleLinkClick}>
                Pricing
              </a>
            </li>
            <li>
              <a href='#services' className='page-scroll' onClick={handleLinkClick}>
                Services
              </a>
            </li>
            <li>
              <a href='#testimonials' className='page-scroll' onClick={handleLinkClick}>
                Testimonials
              </a>
            </li>
            
            <li>
            
            <a className="btn-right" href="tel:9031062011" style={{color:'#ef630b'}}>Call Us: 9031062011</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
